import React, { useState, useCallback } from "react";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import authService from "../api/authService";
import useGeneralStore from "../store/general.state";

const { Option } = Select;

function AutoSuggestPageId({ value = [], setValue }) {
  const { selectedAdAccount, searchPageIdByBusinessManager } =
    useGeneralStore();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchSuggestions = async (query) => {
    setLoading(true);
    try {
      const data = await searchPageIdByBusinessManager(
        selectedAdAccount?.business_manager,
        query
      );
      setOptions(data);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchSuggestions = useCallback(
    debounce(fetchSuggestions, 300),
    []
  );

  const handleSearch = (query) => {
    if (query.length > 2) {
      debouncedFetchSuggestions(query);
    } else {
      setOptions([]);
    }
  };

  const handleChange = (selectedValues) => {
    setValue(selectedValues);
  };

  return (
    <Select
      showSearch
      value={value}
      placeholder="Search and select page"
      notFoundContent={loading ? <Spin size="small" /> : null}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      style={{ width: "100%" }}
    >
      {options.map((option) => (
        <Option key={option.name} value={option.page_id}>
          {option.name}
        </Option>
      ))}
    </Select>
  );
}

export default AutoSuggestPageId;
