import { useEffect } from "react";

export function useInterval(callback, delay, isAuth = false) {
  useEffect(() => {
    if (!isAuth) {
      return;
    }

    const intervalId = setInterval(() => {
      callback();
    }, delay);

    // Cleanup on component unmount
    return () => clearInterval(intervalId);
  }, [callback, delay, isAuth]);
}
