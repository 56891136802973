import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import authService from "./api/authService";
import "./App.css";
import DashboardPage from "./pages/DashboardPage";
import CampaignDashboard from "./pages/campaigns/CampaignDashboard";
import LoginPage from "./pages/LoginPage";
import AdsetsDashboard from "./pages/adsets/AdsetsDashboard";
import { useInterval } from "./utiles/useInterval";
import useGeneralStore from "./store/general.state";

function RequireAuth({ children }) {
  const isAuthenticated = !!authService.getToken();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return children;
}

function App() {
  const { fetchAdAccounts } = useGeneralStore();
  const isAuthenticated = !!authService.getToken();
  useInterval(fetchAdAccounts, 60000, isAuthenticated);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/dashboard"
          element={
            <RequireAuth>
              <DashboardPage />
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard/:id"
          element={
            <RequireAuth>
              <DashboardPage />
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard/:idAccount/campaigns/:id"
          element={
            <RequireAuth>
              <CampaignDashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard/:idAccount/campaigns/:idCampaigns/adsets/:id"
          element={
            <RequireAuth>
              <AdsetsDashboard />
            </RequireAuth>
          }
        />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </Router>
  );
}

export default App;
