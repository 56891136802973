import React, { useState } from "react";
import { TreeSelect } from "antd";


const updatedTreeData = [
  {
    title: "Facebook",
    value: "facebook",
    children: [
      { title: "Facebook Feed", value: "facebook:feed" },
      { title: "Facebook Right Hand Column", value: "facebook:right_hand_column" },
      { title: "Facebook Marketplace", value: "facebook:marketplace" },
      { title: "Facebook Video Feeds", value: "facebook:video_feeds" },
      { title: "Facebook Story", value: "facebook:story" },
      { title: "Facebook Search", value: "facebook:search" },
      { title: "Facebook Instream Video", value: "facebook:instream_video" },
      { title: "Facebook Reels", value: "facebook:facebook_reels" },
      { title: "Facebook Profile Feed", value: "facebook:profile_feed" },
    ]
  },
  {
    title: "Instagram",
    value: "instagram",
    children: [
      { title: "Instagram Stream", value: "instagram:stream" },
      { title: "Instagram Story", value: "instagram:story" },
      { title: "Instagram Explore", value: "instagram:explore" },
      { title: "Instagram Explore Home", value: "instagram:explore_home" },
      { title: "Instagram Reels", value: "instagram:reels" },
      { title: "Instagram Profile Feed", value: "instagram:profile_feed" },
      { title: "Instagram Ig Search", value: "instagram:ig_search" },
      { title: "Instagram Profile Reels", value: "instagram:profile_reels" },
    ]
  },
  {
    title: "Audience Network",
    value: "audience_network",
    children: [
      { title: "Audience Network Classic", value: "audience_network:classic" },
      { title: "Audience Network Rewarded Video", value: "audience_network:rewarded_video" },
    ]
  },
  {
    title: "Messenger",
    value: "messenger",
    children: [
      { title: "Messenger Home", value: "messenger:messenger_home" },
      { title: "Messenger Sponsored Messages", value: "messenger:sponsored_messages" },
      { title: "Messenger Story", value: "messenger:story" },
    ]
  },
]

const SelectTreePlacement = ({
  value,
  setValue,
  // selectedOptions,
  // setSelectedOptions,
}) => {
  // console.log("selectedOptions", selectedOptions);

  // Helper function to find a node by value
  const findNode = (data, value) => {
    for (let node of data) {
      if (node.value === value) return node;
      if (node.children) {
        const childNode = findNode(node.children, value);
        if (childNode) return childNode;
      }
    }
    return null;
  };

  // Helper function to get all children of a node
  const getAllChildren = (data, value) => {
    let children = [];
    const node = findNode(data, value);
    if (node && node.children) {
      children = node.children.map((child) => child.value);
      node.children.forEach((child) => {
        children = [...children, ...getAllChildren(data, child.value)];
      });
    }
    return children;
  };

  // Handle value change
  const onChange = (newValue) => {
    setValue(newValue);
    // console.log("Selected value:", newValue);

    // Collect all values currently selected
    // const currentlySelectedValues = new Set(value);

    // Add children of newly selected parents
    // newValue.forEach((val) => {
    //   const children = getAllChildren(treeData, val);
    //   children.forEach((childVal) => currentlySelectedValues.add(childVal));
    // });

    // Update selected values state
    // const updatedValues = Array.from(currentlySelectedValues);
    // setValue(updatedValues);

    // Map selected values to their corresponding titles
    // const updatedOptions = updatedValues.map((val) => {
    //   const node = findNode(treeData, val);
    //   return node
    //     ? { title: node.title, value: node.value }
    //     : { title: val, value: val };
    // });

    // setSelectedOptions(updatedOptions);
  };

  const filterTreeNode = (inputValue, treeNode) => {
    const { title } = treeNode.props;
    return title.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <TreeSelect
      showSearch
      style={{ width: "100%" }}
      value={value}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      placeholder="Please select"
      allowClear
      multiple
      onChange={onChange}
      treeData={updatedTreeData}
      filterTreeNode={filterTreeNode}
    />
  );
};

export default SelectTreePlacement;
