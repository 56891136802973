import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  Menu,
  Select,
  Steps,
  Drawer,
  Space,
  message,
  Breadcrumb,
} from "antd";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import TableComponent from "../../components/TableComponent";
import useGeneralStore from "../../store/general.state";
import ThirdStepForm from "../../components/ThirdStepForm";
import { Header } from "../../components/Header";
import { EditAds } from "./EditAds";

const { Option } = Select;

function AdsetsDashboard() {
  let { id } = useParams();
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const createValue = url.searchParams.get("create");

  const {
    loading,
    allAdSets,
    newCampaignData,
    createCampaign,
    setSelectedAdAccount,
    selectedAdAccount,
    allAddAccounts,
    fetchAllAdSets,
    fetchCampaignById,
  } = useGeneralStore();

  const navigate = useNavigate();
  const [values, setValues] = useState({
    editData: "",
    isEdit: false,
  });
  const [open, setOpen] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      campaign_name: null,
      objective: "OUTCOME_ENGAGEMENT",
      buying_type: "AUCTION",
    },
  });

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data) => {
    createCampaign(
      {
        ad_account: selectedAdAccount?.id,
        ...data,
      },
      onClose
    );
  };

  const validateAndProceed = async () => {
    const isValid = await trigger();
    if (isValid) {
      handleSubmit(onSubmit)();
    } else {
      message.error("Something went wrong.");
    }
  };

  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent((prev) => prev + 1);
  };

  const prev = () => {
    setCurrent((prev) => prev - 1);
  };

  const steps = [
    {
      title: "New Ads",
      content: <ThirdStepForm next={next} close={onClose} />,
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const showDrawer = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (id) {
      fetchAllAdSets(id);
    }

    if (createValue) {
      showDrawer();
    }
  }, [id]);

  return (
    <div>
      <Header />

      <div>
        <div>
          <Breadcrumb
            separator=">"
            items={[
              {
                title: (
                  <Link to={`/dashboard/${selectedAdAccount?.id}`}>
                    All Campaigns
                  </Link>
                ),
              },
              {
                title: (
                  <Link
                    to={`/dashboard/${selectedAdAccount?.id}/campaigns/${newCampaignData?.id}`}
                  >
                    Campaigns [{newCampaignData?.id}]
                  </Link>
                ),
              },
              {
                title: `Adsets [${id}]`,
              },
            ]}
          />
        </div>
      </div>

      <div className="header__table">
        <Button disabled={selectedAdAccount?.disabled} onClick={showDrawer}>
          + Create
        </Button>
      </div>
      <TableComponent
        step={2}
        loading={loading}
        data={allAdSets}
        setValues={setValues}
      />
      <Drawer
        title="Create a new account"
        width={1000}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        }
      >
        <Steps current={current} items={items} />
        <div>{steps[current].content}</div>
      </Drawer>

      <Drawer
        title="Edit a Ads"
        width={1000}
        onClose={() => setValues({ ...values, isEdit: false })}
        open={values.isEdit}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        }
      >
        <EditAds
          data={values?.editData}
          close={() => setValues({ ...values, isEdit: false })}
        />
      </Drawer>
    </div>
  );
}

export default AdsetsDashboard;
