import { create } from "zustand";
import api from "../api/apiService";
import { message } from "antd";
import { createJSONStorage, persist } from "zustand/middleware";

const initialState = {
  loading: false,
  selectedAdAccount: null,
  newCampaignData: null,
  newAdsetData: null,
  allAddAccounts: [],
  campaignsByAdAccount: [],
  campaignsById: [],
  allAdSets: [],
  paginationTotal: 0,
};

const useGeneralStore = create(
  persist(
    (set, get) => ({
      ...initialState,
      fetchAdAccounts: async () => {
        const response = await api.get("/ad-accounts/");
        const accounts = response?.data?.results || [];
        set({ allAddAccounts: accounts });
      },
      fetchAllAddAccounts: async (id, navigate) => {
        try {
          set({ loading: true });
          const response = await api.get("/ad-accounts/");
          const accounts = response?.data?.results || [];

          if (accounts?.length && accounts?.length === 1) {
            set({
              allAddAccounts: accounts,
              selectedAdAccount: accounts[0],
              loading: false,
            });
            await get().fetchAdAccountByCampaigns(accounts[0].id);
          } else if (id) {
            await get().fetchAdAccountByCampaigns(id);
            set({
              allAddAccounts: accounts,
              loading: false,
            });
            navigate(`/dashboard/${id}`);
          } else if (!id && accounts?.length) {
            await get().fetchAdAccountByCampaigns(accounts[0]?.id);
            set({
              allAddAccounts: accounts,
              selectedAdAccount: accounts[0],
              loading: false,
            });
            navigate(`/dashboard/${accounts[0]?.id}`);
          }
        } catch (error) {
          console.error("Error fetching ad accounts:", error);
          set({ allAddAccounts: [], loading: false });
        }
      },
      fetchAdAccountByCampaigns: async (id, page = 1) => {
        try {
          set({ loading: true });
          const response = await api.get(
            `/ad-accounts/${id}/campaigns/?page=${page}`
          );
          set({
            campaignsByAdAccount: response?.data?.results || [],
            paginationTotal: response?.data?.count || 0,
            loading: false,
          });
        } catch (error) {
          console.error("Error fetching campaigns by ad account:", error);
          set({ campaignsByAdAccount: [], paginationTotal: 0, loading: false });
        }
      },
      fetchCampaignById: async (id) => {
        try {
          set({ loading: true });
          const response = await api.get(`/campaigns/${id}/adsets`);
          set({
            campaignsById: response?.data?.results || [],
            paginationTotal: 0,
            loading: false,
          });
        } catch (error) {
          set({ campaignsById: [], paginationTotal: 0, loading: false });
        }
      },
      updateCampaignStatus: async (id, status) => {
        try {
          set({ loading: true });
          const response = await api.patch(`/campaigns/${id}/`, {
            status: status ? "ACTIVE" : "PAUSED",
          });
          if (response && response?.data?.ad_account) {
            await get().fetchAdAccountByCampaigns(response?.data?.ad_account);
          }
        } catch (error) {
          console.error("Error fetching campaigns by ad account:", error);
          set({ campaignsByAdAccount: [], loading: false });
        }
      },
      updateAdsetStatus: async (id, status) => {
        try {
          set({ loading: true });
          const response = await api.patch(`/adsets/${id}/`, {
            status: status ? "ACTIVE" : "PAUSED",
          });
          await get().fetchCampaignById(response?.data?.campaign);
          set({ loading: false });
        } catch (error) {
          console.error("Error fetching campaigns by ad account:", error);
          set({ campaignsByAdAccount: [], loading: false });
        }
      },
      updateAdsStatus: async (id, status) => {
        try {
          set({ loading: true });
          const response = await api.patch(`/ads/${id}/`, {
            status: status ? "ACTIVE" : "PAUSED",
          });
          await get().fetchAllAdSets(response?.data?.adset);
          set({ loading: false });
        } catch (error) {
          console.error("Error fetching campaigns by ad account:", error);
          set({ campaignsByAdAccount: [], loading: false });
        }
      },
      setSelectedAdAccount: (id) => {
        const allDataAccount = get().allAddAccounts;
        if (allDataAccount && allDataAccount?.length) {
          const itemSelected = allDataAccount?.find((i) => i?.id == id);
          set({ selectedItem: itemSelected, selectedAdAccount: itemSelected });
        }
      },
      createCampaign: async (data, close, navigate) => {
        try {
          set({ loading: true });
          const response = await api.post("/campaigns/", data);
          set({ newCampaignData: response?.data || null, loading: false });
          close();
          navigate(
            `/dashboard/${data?.ad_account}/campaigns/${response?.data?.id}?create=true`
          );
        } catch (error) {
          message.error("Something went wrong, please try again!.");
          set({ newCampaignData: null, loading: false });
          return { error: error?.response?.data?.detail };
        }
      },
      editCampaign: async (id, accountId, data, close) => {
        try {
          set({ loading: true });
          await api.patch(`/adsets/${id}/`, data);
          await get().fetchCampaignById(accountId);
          close();
          set({ loading: false });
        } catch (error) {
          message.error("Something went wrong, please try again!.");
          set({ loading: false });
          close();
        }
      },
      editAds: async (id, campaignId, data, close) => {
        try {
          set({ loading: true });
          await api.patch(`/ads/${id}/`, data);
          await get().fetchAllAdSets(campaignId);
          set({ loading: false });
          close();
        } catch (error) {
          message.error("Something went wrong, please try again!.");
          set({ loading: false });
          close();
        }
      },
      setNewCampaignData: (data) => {
        set({ newCampaignData: data });
      },
      setAdsData: (data) => {
        set({ newAdsetData: data });
      },
      searchPageIdByBusinessManager: async (id, text) => {
        try {
          const response = await api.get(
            `/pages/?business_manager=${id}&search=${text}`
          );
          return response?.data?.results;
        } catch (error) {
          console.error("Error fetching campaigns by ad account:", error);
        }
      },
      createAdSet: async (data) => {
        try {
          const response = await api.post("/adsets/", data);
          set({ newAdsetData: response?.data || null });
          return { data: response?.data, error: null };
        } catch (error) {
          console.error("Error fetching campaigns by ad account:", error);
          return { data: null, error };
        }
      },
      fetchAllAdSets: async (id) => {
        try {
          set({ loading: true });
          const response = await api.get(`adsets/${id}/ads`);
          set({
            allAdSets: response?.data?.results || [],
            paginationTotal: 0,
            loading: false,
          });
        } catch (error) {
          console.error("Error fetching campaigns by ad account:", error);
          set({ allAdSets: [], paginationTotal: 0, loading: false });
        }
      },
      fetchAllPagePosts: async (pageId) => {
        try {
          const response = await api.get(`/${pageId}/posts`);
          return response?.data;
        } catch (error) {
          console.error("Error fetching campaigns by ad account:", error);
          return null;
        }
      },
      createdAd: async (formData, campaignId, close) => {
        try {
          set({ loading: true });
          const response = await api.post("/ads/", formData);
          await api.patch(`/ads/${response?.data?.id}/`, {
            status: "ACTIVE",
          });
          await api.patch(`/adsets/${response?.data?.adset}/`, {
            status: "ACTIVE",
          });
          await api.patch(`/campaigns/${campaignId}/`, {
            status: "ACTIVE",
          });
          await get().fetchAllAdSets(response?.data?.adset);
          close();
          set({ loading: false });
        } catch (error) {
          console.error("Error fetching campaigns by ad account:", error);
          set({ loading: false });
        }
      },
    }),
    {
      name: "general-holadigital-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useGeneralStore;
