import { Controller, useForm } from "react-hook-form";
import { Button, Select } from "antd";
import { useEffect, useState } from "react";
import AutoSuggestPageId from "../../components/AutoSuggestPageId";
import AutoSuggestSelect from "../../components/AutoSuggestSelect";
import SelectTreePlacement from "../../components/SelectTreePlacement";
import useGeneralStore from "../../store/general.state";
import { useParams } from "react-router-dom";

const { Option } = Select;

export const EditCampaign = ({ data, close }) => {
  let { id } = useParams();
  const { loading, editCampaign } = useGeneralStore();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors, dirtyFields, isDirty },
    reset,
    watch,
  } = useForm({
    defaultValues: {
      name: "",
      destination_type: "",
      page_id: 0,
      optimization_goal: "",
      budget: "",
      amount: "",
      budget_type: "daily_budget",
      startDate: "",
      end_date: "",
      end_time: "",
      minAge: 0,
      maxAge: 0,
      gender: "",
      location: "",
      languages: [],
      placements: data?.targeting?.publisher_platforms || [],
    },
  });

  const isPlacementsChanged = watch("placements");

  const genderCheck = (genders) => {
    if (genders?.includes(1) && genders?.includes(2)) {
      return "all";
    } else if (genders?.includes(1)) {
      return 1;
    } else if (genders?.includes(2)) {
      return 2;
    } else {
      return "all";
    }
  };

  useEffect(() => {
    if (data) {
      const findGender = genderCheck(data?.targeting?.genders);

      reset({
        name: data?.name || "",
        destination_type: data?.destination_type || "",
        location:
          data?.targeting?.geo_locations?.cities ||
          data?.targeting?.geo_locations ||
          [],
        page_id: data?.promoted_object?.page_id || "",
        optimization_goal: data?.optimization_goal,
        end_time: data?.end_time || new Date(),
        daily_budget: data?.daily_budget || "0",
        minAge: data?.targeting?.age_min || 0,
        maxAge: data?.targeting?.age_max || 0,
        gender: findGender,
        placements: data?.targeting?.publisher_platforms || [],
      });

      console.log("data", data);
    }
  }, [data]);

  const onSubmit = (values) => {
    console.log("values", values);

    if (!isDirty) return;
    const changedValues = {};

    Object.keys(dirtyFields).forEach((key) => {
      changedValues[key] = values[key];
    });

    const body = {};

    if (changedValues.page_id) {
      body.promoted_object = {
        page_id: changedValues.page_id,
      };
    }

    if (isPlacementsChanged) {
      const placements = isPlacementsChanged;

      placements.forEach((placement) => {
        const isMainPlatform = [
          "facebook",
          "instagram",
          "messenger",
          "audience_network",
        ].includes(placement);

        body.targeting = body.targeting || {};

        if (isMainPlatform) {
          body.targeting.publisher_platforms =
            body.targeting.publisher_platforms || [];
          body.targeting.publisher_platforms.push(placement);
        } else {
          const [publisherType, publisherPlacement] = placement.split(":");

          body.targeting[`${publisherType}_positions`] =
            body.targeting[`${publisherType}_positions`] || [];
          body.targeting[`${publisherType}_positions`].push(publisherPlacement);
        }
      });
    }

    if (
      changedValues.minAge ||
      changedValues.maxAge ||
      changedValues.gender ||
      isPlacementsChanged
    ) {
      body.targeting = {
        genders:
          changedValues.gender === "all" || values.gender === "all"
            ? [1, 2]
            : [changedValues.gender || values.gender],
        age_min: changedValues.minAge || values.minAge,
        age_max: changedValues.maxAge || values.maxAge,
        geo_locations: (changedValues.location || values.location)?.map(
          (loc) => {
            if (typeof loc === "string" && loc.startsWith("city:")) {
              return loc;
            }
            return loc?.key ? `city:${loc?.key}` : `city:${loc}`;
          }
        ),
        publisher_platforms: isPlacementsChanged,
      };
    }

    Object.keys(changedValues).forEach((key) => {
      if (
        ![
          "page_id",
          "minAge",
          "maxAge",
          "gender",
          "location",
          "placements",
        ].includes(key)
      ) {
        body[key] = changedValues[key];
      }
    });

    editCampaign(data?.id, id, body, close);
  };

  return (
    <div>
      <form className="form__wrapper" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Controller
            name="name"
            control={control}
            rules={{ required: "Ad set name is required" }}
            render={({ field }) => (
              <>
                <p>Ad Set Name</p>
                <input
                  id="name"
                  placeholder="New Engagement ad set"
                  {...field}
                />
                {errors?.name && (
                  <div className="error-message">{errors?.name?.message}</div>
                )}
              </>
            )}
          />

          <Controller
            name="destination_type"
            control={control}
            rules={{ required: "Destination Type is required" }}
            render={({ field }) => (
              <>
                <p>Campaign Objective</p>
                <Select
                  {...field}
                  style={{ width: "100%" }}
                  placeholder="Choose a destination type"
                >
                  <Option value="MESSENGER">Messaging Apps</Option>
                  <Option value="on_your_ad" disabled>
                    On Your Ad
                  </Option>
                  <Option value="calls" disabled>
                    Calls
                  </Option>
                  <Option value="website" disabled>
                    Website
                  </Option>
                  <Option value="app" disabled>
                    App
                  </Option>
                  <Option value="facebook_page" disabled>
                    Facebook Page
                  </Option>
                </Select>
                {errors.objective && (
                  <div className="error-message">
                    {errors.destination_type?.message}
                  </div>
                )}
              </>
            )}
          />

          <p>Page ID</p>
          <Controller
            name="page_id"
            control={control}
            render={({ field }) => (
              <AutoSuggestPageId
                value={field?.value}
                setValue={(value) => {
                  setValue("page_id", value);
                  field.onChange(value);
                  field.onBlur();
                }}
              />
            )}
          />

          <Controller
            name="optimization_goal"
            control={control}
            rules={{ required: "Optimization goal is required" }}
            render={({ field }) => (
              <>
                <p>Performance Goal</p>
                <Select
                  {...field}
                  style={{ width: "100%" }}
                  placeholder="Choose a optimization goal"
                >
                  <Option value="CONVERSATIONS">
                    Maximize Number of Conversations
                  </Option>
                  <Option value="clicks_number" disabled>
                    Maximize Number of Link Clicks
                  </Option>
                </Select>
                {errors.performanceGoal && (
                  <div className="error-message">
                    {errors.optimization_goal.message}
                  </div>
                )}
              </>
            )}
          />

          <div className="budget__wrapper">
            <Controller
              name="budget_type"
              control={control}
              rules={{ required: "Budget type is required" }}
              render={({ field }) => (
                <div>
                  <p>Budget Type</p>
                  <Select
                    {...field}
                    style={{ width: "100%" }}
                    placeholder="Choose budget type"
                  >
                    <Option value="daily_budget">Daily Budget</Option>
                    <Option value="lifetime_budget">Lifetime Budget</Option>
                  </Select>
                  {errors.budget && (
                    <div className="error-message">{errors.budget.message}</div>
                  )}
                </div>
              )}
            />

            <Controller
              name="daily_budget"
              control={control}
              rules={{ required: "Budget is required" }}
              render={({ field }) => (
                <>
                  <input
                    type="number"
                    id="amount"
                    placeholder="$20.00"
                    {...field}
                  />
                  {errors.amount && (
                    <div className="error-message">{errors.amount.message}</div>
                  )}
                </>
              )}
            />
          </div>

          {/* <div className="date-time__wrapper">
            <div className="time__wrapper">
              <Controller
                name="end_date"
                control={control}
                render={({ field }) => (
                  <div className="date">
                    <p>End Date</p>
                    <input
                      type="date"
                      id="end_date"
                      placeholder="Select end date"
                      {...field}
                    />
                    {errors.end_date && (
                      <div className="error-message">
                        {errors.end_date.message}
                      </div>
                    )}
                  </div>
                )}
              />

              <div className="end__date">
                <Controller
                  name="end_time"
                  control={control}
                  render={({ field }) => (
                    <>
                      <input
                        type="time"
                        id="end_time"
                        placeholder="Select end time"
                        {...field}
                      />
                      {errors.end_time && (
                        <div className="error-message">
                          {errors.end_time.message}
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
            </div>
          </div> */}

          <div className="age__wrapper">
            <Controller
              name="minAge"
              control={control}
              rules={{
                required: "Minimum age is required",
                min: 18,
                max: 65,
              }}
              render={({ field }) => (
                <div className="age">
                  <p>Minimum Age</p>
                  <input
                    id="minAge"
                    type="number"
                    placeholder="Min age"
                    {...field}
                  />
                  {errors.minAge && (
                    <div className="error-message">{errors.minAge.message}</div>
                  )}
                </div>
              )}
            />
            <Controller
              name="maxAge"
              control={control}
              render={({ field }) => (
                <div className="age">
                  <p>Maximum Age</p>
                  <input
                    id="maxAge"
                    type="number"
                    placeholder="Max age"
                    {...field}
                  />
                  {errors.maxAge && (
                    <div className="error-message">{errors.maxAge.message}</div>
                  )}
                </div>
              )}
            />
          </div>

          <Controller
            name="gender"
            control={control}
            rules={{ required: "Gender is required" }}
            render={({ field }) => (
              <div>
                <p>Gender</p>
                <Select
                  {...field}
                  style={{ width: "100%" }}
                  placeholder="Gender"
                >
                  <Option value="all">All</Option>
                  <Option value={1}>Male</Option>
                  <Option value={2}>Female</Option>
                </Select>

                {errors.gender && (
                  <div className="error-message">{errors.gender.message}</div>
                )}
              </div>
            )}
          />

          <div>
            <p>Location</p>
            <Controller
              name="location"
              control={control}
              render={({ field }) => (
                <AutoSuggestSelect
                  value={field.value}
                  setValue={(value) => {
                    setValue("location", value);
                    field.onChange(value);
                    field.onBlur();
                  }}
                />
              )}
            />
          </div>

          <div>
            <p>Placements</p>
            <Controller
              name="placements"
              control={control}
              render={({ field }) => (
                <SelectTreePlacement
                  value={field.value}
                  setValue={(value) => {
                    setValue("placements", value);
                    field.onChange(value);
                    field.onBlur();
                  }}
                />
              )}
            />
          </div>
        </div>

        <Button disabled={!isDirty || loading} type="primary" htmlType="submit">
          Update
        </Button>
      </form>
    </div>
  );
};
