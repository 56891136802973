import { Dropdown, Menu } from "antd";
import useGeneralStore from "../store/general.state";
import { useNavigate } from "react-router-dom";
import authService from "../api/authService";
// import "../tabs.css";

export const Header = () => {
  const navigate = useNavigate();
  const { allAddAccounts, setSelectedAdAccount, selectedAdAccount } =
    useGeneralStore();

  const secondStyle = {
    backgroundImage: 'url("./icon.png")',
    backgroundPosition: "0px -333px",
    backgroundSize: "auto",
    width: "24px",
    height: "24px",
    backgroundRepeat: "no-repeat",
    display: "inline-block",
  };

  const style = {
    backgroundImage: 'url("./icon.png")',
    backgroundPosition: "0px -330px",
    backgroundSize: "auto",
    width: "24px",
    height: "24px",
    backgroundRepeat: "no-repeat",
    display: "inline-block",
  };

  const handleLogout = () => {
    authService.logout();
    navigate("/login");
  };

  const handleMenuClick = async (e) => {
    const { key } = e;
    setSelectedAdAccount(key);
    navigate(`/dashboard/${key}`);
  };

  const dropdownItems = allAddAccounts.map((account) => ({
    label: (
      <div className="dropdown__options">
        {/* <i
          alt=""
          data-visualcompletion="css-img"
          className="img"
          style={style}
        ></i> */}
        <div
          style={{
            opacity: account?.disabled ? "0.5" : "1",
            color: account?.disabled ? "red" : "black",
          }}
        >
          <h2 style={{ color: account?.disabled ? "red" : "black" }}>
            {account?.name} {(account?.disabled && "⛔Disabled") || ""}{" "}
            {(selectedAdAccount?.id === account?.id && "✅") || ""}
          </h2>
          <span>Ad account ID: {account?.ad_account_id}</span>
        </div>
      </div>
    ),
    key: account?.id,
  }));

  return (
    <>
      <div className="header__wrapper">
        <div className="dropdown">
          <h1>Ad accounts</h1>
          <Dropdown
            overlay={<Menu items={dropdownItems} onClick={handleMenuClick} />}
            trigger={["click"]}
          >
            <a onClick={(e) => e.preventDefault()}>
              {/* <i
              alt=""
              data-visualcompletion="css-img"
              className="img"
              style={secondStyle}
            ></i>{" "} */}
              <p
                style={{
                  color: selectedAdAccount?.disabled ? "red" : "black",
                  margin: 0,
                }}
              >
                {(selectedAdAccount?.disabled && "⛔") || ""}
                {selectedAdAccount?.name} ({selectedAdAccount?.ad_account_id})
              </p>
            </a>
          </Dropdown>
        </div>

        <div>
          <div onClick={handleLogout}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path
                d="M17 16L21 12M21 12L17 8M21 12L7 12M13 16V17C13 18.6569 11.6569 20 10 20H6C4.34315 20 3 18.6569 3 17V7C3 5.34315 4.34315 4 6 4H10C11.6569 4 13 5.34315 13 7V8"
                stroke="#374151"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      </div>
      {/* <div class="page">
        <div class="pcss3t pcss3t-effect-scale pcss3t-theme-1">
          <input
            type="radio"
            name="pcss3t"
            checked
            id="tab1"
            class="tab-content-first"
          />
          <label for="tab1">
            <i class="icon-bolt"></i>Tesla
          </label>

          <input type="radio" name="pcss3t" id="tab2" class="tab-content-2" />
          <label for="tab2">
            <i class="icon-picture"></i>da Vinci
          </label>

          <input type="radio" name="pcss3t" id="tab3" class="tab-content-3" />
          <label for="tab3">
            <i class="icon-cogs"></i>Einstein
          </label>

          <ul>
            <li class="tab-content tab-content-first typography">1</li>

            <li class="tab-content tab-content-2 typography">2</li>

            <li class="tab-content tab-content-3 typography">3</li>
          </ul>
        </div>
      </div> */}
    </>
  );
};
