import React, { useState } from "react";
import { Table, Switch } from "antd";
import PropTypes from "prop-types";
import useGeneralStore from "../store/general.state";
import { Link } from "react-router-dom";

function TableComponent({
  id,
  step = 0,
  data,
  setValues,
  loading = false,
  fetchAdAccountByCampaigns,
}) {
  const {
    paginationTotal,
    selectedAdAccount,
    updateAdsStatus,
    updateCampaignStatus,
    updateAdsetStatus,
    setNewCampaignData,
    setAdsData,
  } = useGeneralStore();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const handleSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  };

  const handleSwitchChange = async (step, checked, key) => {
    if (key) {
      if (step === 0) {
        updateCampaignStatus(key, checked);
      } else if (step === 1) {
        updateAdsetStatus(key, checked);
      } else if (step === 2) {
        updateAdsStatus(key, checked);
      }
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: handleSelectChange,
  };

  const columns = [
    {
      title: "Off/On",
      dataIndex: "offOn",
      key: "offOn",
      render: (text, record) => {
        return (
          <Switch
            disabled={selectedAdAccount?.disabled}
            checked={record.status == "ACTIVE" && true}
            onChange={(checked) => handleSwitchChange(step, checked, record.id)}
          />
        );
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "CAMPAIGN NAME",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        if (step !== 2) {
          return (
            <Link
              onClick={() => {
                if (step === 0) {
                  setNewCampaignData(record);
                } else if (step === 1) {
                  setAdsData(record);
                }
              }}
              to={`/dashboard/${selectedAdAccount?.id}/${
                step === 1
                  ? `campaigns/${record?.campaign}/adsets`
                  : "campaigns"
              }/${record?.id}`}
            >
              {text}
            </Link>
          );
        } else {
          return <Link>{text}</Link>;
        }
      },
    },
    {
      title: "FREQUENCY",
      dataIndex: "frequency",
      key: "frequency",
    },
    {
      title: "IMPRESSIONS",
      dataIndex: "impressions",
      key: "impressions",
    },
    {
      title: "REACH",
      dataIndex: "reach",
      key: "reach",
    },
    {
      title: "SPEND",
      dataIndex: "spend",
      key: "spend",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (i) => {
        if (step !== 2) {
          return (
            <>
              <Link
                onClick={() => {
                  if (step === 0) {
                    setNewCampaignData(i);
                  } else if (step === 1) {
                    setAdsData(i);
                  }
                }}
                to={`/dashboard/${selectedAdAccount?.id}/${
                  step === 1 ? `campaigns/${i?.campaign}/adsets` : "campaigns"
                }/${i?.id}`}
              >
                View
              </Link>
            </>
          );
        }
      },
    },
    {
      ...(step !== 0 &&
        !selectedAdAccount?.disabled && {
          title: "",
          dataIndex: "",
          key: "",
          render: (data) => {
            return (
              <a onClick={() => setValues({ editData: data, isEdit: true })}>
                Edit
              </a>
            );
          },
        }),
    },
  ];

  return (
    <Table
      rowSelection={rowSelection}
      columns={columns}
      dataSource={data}
      rowKey="key"
      rowClassName={"cursorPointer"}
      loading={loading}
      pagination={{
        total: paginationTotal || 0,
        onChange: (page, pageSize) => {
          if (fetchAdAccountByCampaigns) {
            fetchAdAccountByCampaigns(id, page);
          }
        },
      }}
    />
  );
}

TableComponent.propTypes = {
  data: PropTypes.array.isRequired,
};

export default TableComponent;
