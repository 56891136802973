import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Image, Modal, Select, Table, Input } from "antd";
import authService from "../api/authService";
import useGeneralStore from "../store/general.state";
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;

const ThirdStepForm = ({ close }) => {
  const {
    loading,
    selectedAdAccount,
    newAdsetData,
    fetchAllPagePosts,
    createdAd,
    newCampaignData,
  } = useGeneralStore();
  const [isLoading, setLoading] = useState(false);
  const [iframeShow, setIframeShow] = useState(false);
  const [pagePostData, setPagePostData] = useState([]);
  const [openPagePostModal, setPagePostModal] = useState(false);
  const [error, setError] = useState("");
  const [iframeUrl, setIframeUrl] = useState(null);

  const adFormatMappings = {
    facebook_feed: "DESKTOP_FEED_STANDARD",
    right_hand_column: "RIGHT_COLUMN_STANDARD",
    marketplace: "MARKETPLACE_MOBILE",
    video_feeds: "INSTREAM_VIDEO_FULLSCREEN_MOBILE",
  };

  const {
    control,
    watch,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: `${newAdsetData?.name} Ad`,
      pagePost: "",
      callToAction: "",
      selectedOption: "facebook_feed",
    },
  });

  const callToAction = watch("callToAction");
  const pagePost = watch("pagePost");

  const onSubmit = async (data) => {
    const formData = {
      adset: newAdsetData?.id,
      name: data.name,
      creative: {
        call_to_action: {
          type: data.callToAction,
          value: `https://m.me/${newAdsetData?.promoted_object?.page_id}/`,
        },
        object_story_id: data.pagePost,
      },
    };

    createdAd(formData, newCampaignData?.id, close);
    // try {
    //   const ad = await authService.createAd({
    //     adset: newAdsetData?.id,
    //     name: data.name,
    //     creative: {
    //       object_story_id:
    //         newAdsetData?.promoted_object?.page_id +
    //         "_" +
    //         getValues("pagePost"),
    //     },
    //   });
    //   setCreatedAd(ad);
    //   await authService.publishAd(ad.id);
    // } catch (error) {
    //   setError(error.response.data.detail);
    //   console.error("Error submitting form:", error);
    // }
  };

  const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
  };

  const handlePreviewClick = async () => {
    console.log(adFormatMappings);

    try {
      const fetchedIframeUrl = await authService.fetchIframeUrl(
        selectedAdAccount?.ad_account_id,
        {
          object_story_id: getValues("pagePost"),
          call_to_action: { type: getValues("callToAction") },
        },
        adFormatMappings[getValues("selectedOption")]
      );
      setIframeUrl(fetchedIframeUrl);
    } catch (error) {
      console.error("Error fetching iframe URL:", error);
    }
  };

  const handleRowClick = (record) => {
    if (record) {
      setValue("pagePost", record?.id);
      setValue("message", record?.message);
      setValue("callToAction", record?.call_to_action?.type || "");
      setPagePostModal(false);
    }
  };

  const columns = [
    {
      title: "Thumbnail",
      dataIndex: "full_picture",
      key: "full_picture",
      render: (text) => <Image width={65} src={text} />,
    },
    {
      title: "Post ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <>{text?.split("_")[1]}</>,
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Media",
      dataIndex: "status_type",
      key: "status_type",
      render: (text) => <>{text?.split("_")[1]}</>,
    },
    {
      title: "Date created",
      dataIndex: "created_time",
      key: "created_time",
      render: (text) => <>{moment(text).format("MMM D, YYYY")}</>,
    },
  ];

  return (
    <div>
      <Modal
        width={700}
        title="Listing posts"
        open={openPagePostModal}
        footer={<></>}
        onCancel={() => setPagePostModal(false)}
      >
        <Table
          columns={columns}
          dataSource={pagePostData}
          pagination={false}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        />
      </Modal>
      <form className="form__wrapper" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="name"
          control={control}
          rules={{ required: "Ad Name is required" }}
          render={({ field }) => (
            <>
              <p>Ads Name</p>
              <input id="name" placeholder="New Engagement Ad" {...field} />
              {errors.name && (
                <div className="error-message">{errors.name.message}</div>
              )}
            </>
          )}
        />

        {/* <Controller
          name="headline"
          control={control}
          rules={{
            required: "Headline is required",
            maxLength: {
              value: 25,
              message: "Headline must be 25 characters or less",
            },
          }}
          render={({ field }) => (
            <>
              <p>Headline</p>
              <input
                id="headline"
                placeholder="Headline"
                maxLength={25}
                {...field}
              />
              {errors.headline && (
                <div className="error-message">{errors.headline.message}</div>
              )}
            </>
          )}
        /> */}

        <p>Page Post</p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Controller
            name="pagePost"
            control={control}
            rules={{ required: "Page Post is required" }}
            render={({ field }) => (
              <>
                <input
                  disabled={true}
                  id="pagePost"
                  placeholder="Post ID"
                  {...field}
                />
                {errors.pagePost && (
                  <div className="error-message">{errors.pagePost.message}</div>
                )}
              </>
            )}
          />

          <Button
            loading={isLoading}
            type="primary"
            style={{ marginLeft: "15px" }}
            onClick={() => {
              setLoading(true);
              fetchAllPagePosts(newAdsetData?.promoted_object?.page_id).then(
                (res) => {
                  setPagePostModal(true);
                  setPagePostData(res?.data);
                  setLoading(false);
                }
              );
            }}
          >
            Use existing post
          </Button>
        </div>

        <Controller
          name="callToAction"
          control={control}
          rules={{ required: "Call to Action is required" }}
          render={({ field }) => (
            <>
              <p>Call To Action</p>
              <Select
                id="callToAction"
                placeholder="Select an action"
                {...field}
                onChange={(value) => field.onChange(value)}
                style={{ width: "100%" }}
              >
                <Option value="MESSAGE_PAGE">Send message</Option>
                <Option value="APPLY_NOW">Apply now</Option>
                <Option value="BOOK_NOW">Book now</Option>
                <Option value="CONTACT_US">Contact us</Option>
                <Option value="GET_PROMOTIONS">Get promotions</Option>
                <Option value="GET_QUOTE">Get quote</Option>
                <Option value="INQUIRE_NOW">Inquire now</Option>
                <Option value="LEARN_MORE">Learn more</Option>
                <Option value="ORDER_NOW">Order now</Option>
                <Option value="SHOP_NOW">Shop now</Option>
                <Option value="SIGN_UP">Sign up</Option>
                <Option value="SUBSCRIBE">Subscribe</Option>
              </Select>
              {errors.callToAction && (
                <div className="error-message">
                  {errors.callToAction.message}
                </div>
              )}
            </>
          )}
        />

        <Controller
          name="selectedOption"
          control={control}
          render={({ field }) => (
            <>
              <p>Select Option</p>
              <Select
                {...field}
                style={{ width: "100%" }}
                placeholder="Select an option"
                options={[
                  {
                    label: <span>Facebook</span>,
                    title: "Facebook",
                    options: newAdsetData?.targeting.facebook_positions
                      ? newAdsetData?.targeting.facebook_positions.map(
                          (placement) => {
                            return {
                              label: (
                                <span>
                                  {toTitleCase(placement.replace("_", " "))}
                                </span>
                              ),
                              value: "facebook_" + placement,
                              disabled: placement !== "feed",
                            };
                          }
                        )
                      : [],
                  },
                  {
                    label: <span>Instagram</span>,
                    title: "Instagram",
                    options: newAdsetData?.targeting.instagram_positions
                      ? newAdsetData?.targeting.instagram_positions.map(
                          (placement) => {
                            return {
                              label: (
                                <span>
                                  {toTitleCase(placement.replace("_", " "))}
                                </span>
                              ),
                              value: placement,
                              disabled: true,
                            };
                          }
                        )
                      : [],
                  },
                  {
                    label: <span>Messenger</span>,
                    title: "Messenger",
                    options: newAdsetData?.targeting?.messenger_positions
                      ? newAdsetData?.targeting?.messenger_positions.map(
                          (placement) => {
                            return {
                              label: (
                                <span>
                                  {toTitleCase(placement.replace("_", " "))}
                                </span>
                              ),
                              value: placement,
                              disabled: true,
                            };
                          }
                        )
                      : [],
                  },
                  {
                    label: <span>Audience Network</span>,
                    title: "Audience Network",
                    options: newAdsetData?.targeting?.audience_network_positions
                      ? newAdsetData?.targeting?.audience_network_positions.map(
                          (placement) => {
                            return {
                              label: (
                                <span>
                                  {toTitleCase(placement.replace("_", " "))}
                                </span>
                              ),
                              value: placement,
                              disabled: true,
                            };
                          }
                        )
                      : [],
                  },
                ]}
              ></Select>
            </>
          )}
        />

        {/* <Controller
          name="message"
          control={control}
          render={({ field }) => (
            <>
              <p>Description</p>
              <TextArea
                rows={4}
                id="message"
                placeholder="Description"
                {...field}
              />
              {errors.name && (
                <div className="error-message">
                  {errors.description.message}
                </div>
              )}
            </>
          )}
        /> */}

        <br />
        <br />
        <div className="preview__iframe">
          <div>
            <Button
              disabled={!callToAction || !pagePost}
              type="primary"
              onClick={() => {
                handlePreviewClick();
                setIframeShow(true);
              }}
            >
              Preview iframe
            </Button>
          </div>
          <br />
        </div>

        <Modal
          width={570}
          title="Embedded Content"
          open={iframeShow}
          footer={<></>}
          onCancel={() => setIframeShow(false)}
        >
          <div dangerouslySetInnerHTML={{ __html: iframeUrl }} />
        </Modal>

        <div>
          {error && <p>{error}</p>}
          <Button
            disabled={loading}
            loading={loading}
            className="continue__btn"
            type="primary"
            htmlType="submit"
          >
            Publish
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ThirdStepForm;
