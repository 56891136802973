import api from "./apiService";

const authService = {
  login: async (username, password) => {
    try {
      const response = await api.post("/auth/jwt", {
        username,
        password,
      });
      localStorage.setItem("token", response.data.access);
    } catch (error) {
      throw new Error("Login failed");
    }
  },
  logout: () => {
    localStorage.removeItem("token");
  },
  getToken: () => {
    const tok = localStorage.getItem("token");
    return tok;
  },
  fetchDataBasedOnSelection: async (key) => {
    try {
      const response = await api.get(`/data/${key}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching data based on selection:", error);
      throw error;
    }
  },
  fetchAdAccounts: async () => {
    try {
      const response = await api.get("/ad-accounts/");
      return response.data;
    } catch (error) {
      console.error("Error fetching ad accounts:", error);
      throw error;
    }
  },
  fetchCampaigns: async (adAccountId) => {
    try {
      const response = await api.get(`/ad-accounts/${adAccountId}/campaigns`);
      return response.data;
    } catch (error) {
      console.error("Error fetching campaigns:", error);
      throw error;
    }
  },
  updateCampaignStatus: async (id, status) => {
    try {
      await api.patch(`/campaigns/${id}/`, {
        status,
      });
    } catch (error) {
      throw new Error("Error updating campaign status");
    }
  },
  secondStepForm: async (formData) => {
    console.log("formData", formData);

    try {
      const response = await api.post("/submit-form", formData);
      return response.data;
    } catch (error) {
      console.error("Error submitting form data:", error);
      throw error;
    }
  },
  fetchLocationSuggestions: async (query) => {
    try {
      const response = await api.get(
        `/search?location_types=["country","city"]&type=adgeolocation&q=${query}&locale=en_US`
      );
      const formattedOptions = response.data.data.map((item) => ({
        value: `${item.type}:${item.key}`,
        label: item.name,
      }));
      return formattedOptions;
    } catch (error) {
      console.error("Error fetching location suggestions:", error);
      throw error;
    }
  },
  fetchIframeUrl: async (ad_account_id, creative_spec, ad_format) => {
    try {
      const response = await api.get(
        `${ad_account_id}/generatepreviews?creative=${JSON.stringify(
          creative_spec
        )}&ad_format=${ad_format}`
      );

      return response.data.data[0].body; // Adjust based on your API response structure
    } catch (error) {
      console.error("Error fetching iframe URL:", error);
      throw error;
    }
  },
  createCampaign: async (formData) => {
    try {
      const response = await api.post("/campaigns/", formData);
      console.log("response", response);
      return response.data;
    } catch (error) {
      console.error("Error creating campaign", error);
      throw error;
    }
  },
  createAdSet: async (formData) => {
    try {
      const response = await api.post("/adsets/", formData);
      return response.data;
    } catch (error) {
      console.log("Error creating adset", error);
      throw error;
    }
  },
  createAd: async (formData) => {
    try {
      const response = await api.post("/ads/", formData);
      console.log("response", response);
      return response.data;
    } catch (error) {
      console.log("Error creating ad", error);
      throw error;
    }
  },
  publishAd: async (adId) => {
    try {
      const response = await api.post(`/ads/${adId}/publish/`);
      console.log("response", response);
      return response.data;
    } catch (error) {
      console.log("Error publishing ad", error);
      throw error;
    }
  },
};

export default authService;
