import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  Menu,
  Select,
  Steps,
  Drawer,
  Space,
  message,
  Breadcrumb,
} from "antd";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import TableComponent from "../components/TableComponent";
import useGeneralStore from "../store/general.state";
import { Header } from "../components/Header";

const { Option } = Select;

function DashboardPage() {
  const {
    loading,
    fetchAdAccountByCampaigns,
    createCampaign,
    campaignsByAdAccount,
    setSelectedAdAccount,
    selectedAdAccount,
    allAddAccounts,
    fetchAllAddAccounts,
  } = useGeneralStore();

  let { id } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "New Campaign",
      objective: "OUTCOME_ENGAGEMENT",
      buying_type: "AUCTION",
    },
  });

  const onSubmit = async (data) => {
    createCampaign(
      {
        ad_account: selectedAdAccount?.id,
        ...data,
      },
      onClose,
      navigate
    ).then((res) => {
      if (res?.error) {
        setError(res?.error);
      }
    });
  };

  const validateAndProceed = async () => {
    const isValid = await trigger();
    if (isValid) {
      handleSubmit(onSubmit)();
    }
  };

  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent((prev) => prev + 1);
  };

  const prev = () => {
    setCurrent((prev) => prev - 1);
  };

  const steps = [
    {
      title: "New campaign",
      content: (
        <>
          <form className="form__wrapper">
            <Controller
              name="name"
              control={control}
              rules={{ required: "Campaign name is required" }}
              render={({ field }) => (
                <>
                  <p>Campaign name</p>
                  <input {...field} placeholder="Enter campaign name" />
                  {errors.name && (
                    <div className="error-message">{errors.name.message}</div>
                  )}
                </>
              )}
            />

            <Controller
              name="objective"
              control={control}
              rules={{ required: "Campaign objective is required" }}
              render={({ field }) => (
                <>
                  <p>Campaign objective</p>
                  <Select
                    {...field}
                    style={{ width: "100%" }}
                    placeholder="Choose a campaign objective"
                  >
                    <Option value="OUTCOME_ENGAGEMENT">Engagement</Option>
                    <Option value="Traffic" disabled>
                      Traffic
                    </Option>
                    <Option value="Awareness" disabled>
                      Awareness
                    </Option>
                    <Option value="Leads" disabled>
                      Leads
                    </Option>
                    <Option value="App promotion" disabled>
                      App promotion
                    </Option>
                    <Option value="Sales" disabled>
                      Sales
                    </Option>
                  </Select>
                  {errors.objective && (
                    <div className="error-message">
                      {errors.objective.message}
                    </div>
                  )}
                </>
              )}
            />
            <Controller
              name="buying_type"
              control={control}
              rules={{ required: "Buying Type is required" }}
              render={({ field }) => (
                <>
                  <p>Buying type</p>
                  <Select
                    {...field}
                    style={{ width: "100%" }}
                    placeholder="Choose a Buying Type"
                  >
                    <Option value="AUCTION">Auction</Option>
                    <Option value="Reach and frequency" disabled>
                      Reach and frequency
                    </Option>
                  </Select>
                  {errors.buying_type && (
                    <div className="error-message">
                      {errors.buying_type.message}
                    </div>
                  )}
                </>
              )}
            />

            {error && <div>{error}</div>}
            <Button
              loading={loading}
              disabled={loading}
              type="primary"
              className="continue__btn"
              onClick={validateAndProceed}
            >
              Continue
            </Button>
          </form>
        </>
      ),
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchAllAddAccounts(id || null, navigate);
  }, [id]);

  return (
    <div>
      <Header />
      <div>
        <Breadcrumb
          separator=">"
          items={[
            {
              title: "All Campaigns",
            },
          ]}
        />
      </div>

      <div className="header__table">
        <Button disabled={selectedAdAccount?.disabled} onClick={showDrawer}>
          + Create
        </Button>
      </div>
      <TableComponent
        step={0}
        loading={loading}
        id={id}
        data={campaignsByAdAccount || []}
        fetchAdAccountByCampaigns={fetchAdAccountByCampaigns}
      />
      <Drawer
        title="Create a new account"
        width={1000}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        }
      >
        <Steps current={current} items={items} />
        <div>{steps[current].content}</div>
      </Drawer>
    </div>
  );
}

export default DashboardPage;
