import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  Menu,
  Select,
  Steps,
  Drawer,
  Space,
  message,
  Breadcrumb,
} from "antd";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import authService from "../../api/authService";
import TableComponent from "../../components/TableComponent";
import useGeneralStore from "../../store/general.state";
import SecondStepForm from "../../components/SecondStepForm";
import { Header } from "../../components/Header";
import { EditCampaign } from "./EditCampaign";

const { Option } = Select;

function CampaignDashboard() {
  let { id, idAccount } = useParams();
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const createValue = url.searchParams.get("create");

  const {
    loading,
    campaignsById,
    createCampaign,
    setSelectedAdAccount,
    selectedAdAccount,
    allAddAccounts,
    fetchCampaignById,
  } = useGeneralStore();

  const navigate = useNavigate();
  const [values, setValues] = useState({
    editData: "",
    isEdit: false,
  });
  const [open, setOpen] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      campaign_name: null,
      objective: "OUTCOME_ENGAGEMENT",
      buying_type: "AUCTION",
    },
  });

  const onSubmit = async (data) => {
    createCampaign(
      {
        ad_account: idAccount,
        ...data,
      },
      onClose
    );
  };

  const validateAndProceed = async () => {
    const isValid = await trigger();
    if (isValid) {
      handleSubmit(onSubmit)();
    } else {
      message.error("Something went wrong.");
    }
  };

  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent((prev) => prev + 1);
  };

  const prev = () => {
    setCurrent((prev) => prev - 1);
  };

  const steps = [
    {
      title: "New Adset",
      content: <SecondStepForm next={next} />,
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (id) {
      fetchCampaignById(id);
    }

    if (createValue) {
      showDrawer();
    }
  }, [id]);

  const style = {
    backgroundImage: 'url("./icon.png")',
    backgroundPosition: "0px -330px",
    backgroundSize: "auto",
    width: "24px",
    height: "24px",
    backgroundRepeat: "no-repeat",
    display: "inline-block",
  };

  return (
    <div>
      <Header />
      <div>
        <div>
          <Breadcrumb
            separator=">"
            items={[
              {
                title: (
                  <Link to={`/dashboard/${selectedAdAccount?.id}`}>
                    All Campaigns
                  </Link>
                ),
              },
              {
                title: `Campaign [${id}]`,
              },
            ]}
          />
        </div>
      </div>

      <div className="header__table">
        <Button disabled={selectedAdAccount?.disabled} onClick={showDrawer}>
          + Create
        </Button>
      </div>
      <TableComponent
        step={1}
        loading={loading}
        data={campaignsById}
        setValues={setValues}
      />
      <Drawer
        title="Create a new account"
        width={1000}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        }
      >
        <Steps current={current} items={items} />
        <div>{steps[current].content}</div>
      </Drawer>
      <Drawer
        title="Edit a campaign"
        width={1000}
        onClose={() => setValues({ ...values, isEdit: false })}
        open={values.isEdit}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        }
      >
        <EditCampaign
          data={values?.editData}
          close={() => setValues({ ...values, isEdit: false })}
        />
      </Drawer>
    </div>
  );
}

export default CampaignDashboard;
