import { Controller, useForm } from "react-hook-form";
import { Button, Image, Modal, Select, Table } from "antd";
import { useEffect, useState } from "react";
import AutoSuggestPageId from "../../components/AutoSuggestPageId";
import AutoSuggestSelect from "../../components/AutoSuggestSelect";
import SelectTreePlacement from "../../components/SelectTreePlacement";
import useGeneralStore from "../../store/general.state";
import { useParams } from "react-router-dom";
import moment from "moment";

const { Option } = Select;

export const EditAds = ({ data, close }) => {
  let { id } = useParams();
  const { loading, editAds, newAdsetData, fetchAllPagePosts } =
    useGeneralStore();
  const [isLoading, setLoading] = useState(false);
  const [pagePostData, setPagePostData] = useState([]);
  const [openPagePostModal, setPagePostModal] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields, isDirty },
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      pagePost: "",
      callToAction: "MESSAGE_PAGE",
      selectedOption: "facebook_feed",
    },
  });

  useEffect(() => {
    if (data) {
      console.log("data", data);

      reset({
        name: data?.name || "",
        callToAction: data?.creative?.call_to_action?.type || "",
        pagePost: data?.creative?.object_story_id || "",
      });
    }
  }, [data]);

  const onSubmit = (values) => {
    if (!isDirty) return;
    const changedValues = {};

    Object.keys(dirtyFields).forEach((key) => {
      changedValues[key] = values[key];
    });

    if (changedValues?.callToAction || changedValues?.pagePost) {
      const body = {
        creative: {
          call_to_action: {
            type: changedValues?.callToAction,
            value: `https://m.me/${
              changedValues?.pagePost || values?.pagePost
            }/`,
          },
          object_story_id: changedValues?.pagePost || values?.pagePost,
        },
      };
      editAds(data?.id, id, body, close);
    } else {
      editAds(data?.id, id, changedValues, close);
    }
  };

  const handleRowClick = (record) => {
    if (record) {
      setValue("pagePost", record?.id);
      setValue("message", record?.message);
      setValue("callToAction", record?.call_to_action?.type || "");
      setPagePostModal(false);
    }
  };

  const columns = [
    {
      title: "Thumbnail",
      dataIndex: "full_picture",
      key: "full_picture",
      render: (text) => <Image width={65} src={text} />,
    },
    {
      title: "Post ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <>{text?.split("_")[1]}</>,
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Media",
      dataIndex: "status_type",
      key: "status_type",
      render: (text) => <>{text?.split("_")[1]}</>,
    },
    {
      title: "Date created",
      dataIndex: "created_time",
      key: "created_time",
      render: (text) => <>{moment(text).format("MMM D, YYYY")}</>,
    },
  ];

  return (
    <div>
      <Modal
        width={700}
        title="Listing posts"
        open={openPagePostModal}
        footer={<></>}
        onCancel={() => setPagePostModal(false)}
      >
        <Table
          columns={columns}
          dataSource={pagePostData}
          pagination={false}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        />
      </Modal>
      <form className="form__wrapper" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Controller
            name="name"
            control={control}
            rules={{ required: "Ad Name is required" }}
            render={({ field }) => (
              <>
                <p>Ads Name</p>
                <input id="name" placeholder="New Engagement Ad" {...field} />
                {errors.name && (
                  <div className="error-message">{errors.name.message}</div>
                )}
              </>
            )}
          />

          <p>Page Post</p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Controller
              name="pagePost"
              control={control}
              rules={{ required: "Page Post is required" }}
              render={({ field }) => (
                <>
                  <input id="pagePost" placeholder="Post ID" {...field} />
                  {errors.pagePost && (
                    <div className="error-message">
                      {errors.pagePost.message}
                    </div>
                  )}
                </>
              )}
            />

            <Button
              loading={isLoading}
              type="primary"
              style={{ marginLeft: "15px" }}
              onClick={() => {
                setLoading(true);
                fetchAllPagePosts(newAdsetData?.promoted_object?.page_id).then(
                  (res) => {
                    setPagePostModal(true);
                    setPagePostData(res?.data);
                    setLoading(false);
                  }
                );
              }}
            >
              Use existing post
            </Button>
          </div>

          <Controller
            name="callToAction"
            control={control}
            rules={{ required: "Call to Action is required" }}
            render={({ field }) => (
              <>
                <p>Call To Action</p>
                <Select
                  id="callToAction"
                  placeholder="Select an action"
                  {...field}
                  onChange={(value) => field.onChange(value)}
                  style={{ width: "100%" }}
                >
                  <Option value="MESSAGE_PAGE">Send message</Option>
                  <Option value="APPLY_NOW">Apply now</Option>
                  <Option value="BOOK_NOW">Book now</Option>
                  <Option value="CONTACT_US">Contact us</Option>
                  <Option value="GET_PROMOTIONS">Get promotions</Option>
                  <Option value="GET_QUOTE">Get quote</Option>
                  <Option value="INQUIRE_NOW">Inquire now</Option>
                  <Option value="LEARN_MORE">Learn more</Option>
                  <Option value="ORDER_NOW">Order now</Option>
                  <Option value="SHOP_NOW">Shop now</Option>
                  <Option value="SIGN_UP">Sign up</Option>
                  <Option value="SUBSCRIBE">Subscribe</Option>
                </Select>
                {errors.callToAction && (
                  <div className="error-message">
                    {errors.callToAction.message}
                  </div>
                )}
              </>
            )}
          />

          {/* <Controller
            name="selectedOption"
            control={control}
            render={({ field }) => (
              <>
                <p>Select Option</p>
                <Select
                  {...field}
                  style={{ width: "100%" }}
                  placeholder="Select an option"
                  options={[
                    {
                      label: <span>Facebook</span>,
                      title: "Facebook",
                      options: newAdsetData?.targeting.facebook_positions
                        ? newAdsetData?.targeting.facebook_positions.map(
                            (placement) => {
                              return {
                                label: (
                                  <span>
                                    {toTitleCase(placement.replace("_", " "))}
                                  </span>
                                ),
                                value: "facebook_" + placement,
                                disabled: placement !== "feed",
                              };
                            }
                          )
                        : [],
                    },
                    {
                      label: <span>Instagram</span>,
                      title: "Instagram",
                      options: newAdsetData?.targeting.instagram_positions
                        ? newAdsetData?.targeting.instagram_positions.map(
                            (placement) => {
                              return {
                                label: (
                                  <span>
                                    {toTitleCase(placement.replace("_", " "))}
                                  </span>
                                ),
                                value: placement,
                                disabled: true,
                              };
                            }
                          )
                        : [],
                    },
                    {
                      label: <span>Messenger</span>,
                      title: "Messenger",
                      options: newAdsetData?.targeting?.messenger_positions
                        ? newAdsetData?.targeting?.messenger_positions.map(
                            (placement) => {
                              return {
                                label: (
                                  <span>
                                    {toTitleCase(placement.replace("_", " "))}
                                  </span>
                                ),
                                value: placement,
                                disabled: true,
                              };
                            }
                          )
                        : [],
                    },
                    {
                      label: <span>Audience Network</span>,
                      title: "Audience Network",
                      options: newAdsetData?.targeting
                        ?.audience_network_positions
                        ? newAdsetData?.targeting?.audience_network_positions.map(
                            (placement) => {
                              return {
                                label: (
                                  <span>
                                    {toTitleCase(placement.replace("_", " "))}
                                  </span>
                                ),
                                value: placement,
                                disabled: true,
                              };
                            }
                          )
                        : [],
                    },
                  ]}
                ></Select>
              </>
            )}
          /> */}
        </div>

        <Button disabled={!isDirty || loading} type="primary" htmlType="submit">
          Update
        </Button>
      </form>
    </div>
  );
};
